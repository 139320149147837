import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  option: {
    color: "#343A40",
    fontSize: "14px !important",
    minHeight: "fit-content !important",
  },
  search: {
    fontSize: 14,
    "& .searchBox": {
      padding: "10px 10px 25px 10px",
      borderRadius: "8px",
      border: "0.5px solid #D2D2D2",
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.1)",
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .label": {
      whiteSpace: "nowrap",
      marginRight: 10,
      marginTop: 6,
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        marginBottom: 5,
      },
    },

    "& .mt-10": {
      marginTop: 10,
    },
    "& .my-15": {
      margin: "15px 0",
    },
    "& .mx-20": {
      margin: "0 20px",
    },
    "& .line": {
      width: "1px",
      height: "25px",
      background: "#D2D2D2",
      margin: "0 15px",
    },
    "& .empty": {
      padding: "15%",
      textAlign: "center",
    },
    "& .btn-search": {
      width: "100%",
      borderRadius: 4,
      border: "1px solid #1A427D",
      background: "#1A427D",
      textTransform: "none",
      boxShadow: "none",
    },
    "& .btn-outline": {
      width: "100%",
      borderRadius: 4,
      border: "1px solid #1A427D",
      background: "#fff",
      color: "#1A427D",
      textTransform: "none",
      boxShadow: "none",
    },
    "& .nowrap": {
      whiteSpace: "nowrap",
      marginRight: 10,
    },
    "& .MuiTypography-root": {
      fontSize: 14,
    },
    "& .MuiCheckbox-root": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-root": {
      color: "#1A427D",
      fontSize: 14,
    },
    "& .hiden": {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    "& .filter-data": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "25px 10px 0",
      color: "#343A40",
    },

    "& .empty-filter": {
      textAlign: "center",
      margin: "30px 0 20px",
      color: "#FB7800",
      fontWeight: 500,
    },

    "& .loading": {
      textAlign: "center",
      color: "#1A427D",
      margin: "30px 0 20px",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },

    "& .loading-text": {
      marginTop: 8,
      fontStyle: "italic",
      fontSize: 14,
    },

    "& .expanding": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#1A427D",
    },

    "& .expand-more": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#1A427D",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },

    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
      marginRight: 12,
    },

    "& .MuiInputLabel-root": {
      fontSize: 14,
    },

    "& .age .MuiInputBase-input": {
      color: "#343A40 !important",
    },
    "& .age-err .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "& .err": {
      fontStyle: "italic",
      color: "red",
      fontSize: 13,
      marginTop: "4px",
    },
  },

  bookingImg: {
    zIndex: 9,
    position: "fixed",
    right: "5px",
    bottom: "60px",
    cursor: "pointer",
    transition: "filter 0.3s ease-in-out",
    imageRendering: "pixelated" /* Firefox */,
    imageRendering: "crisp-edges" /* Safari */,
    imageRendering: "-webkit-optimize-contrast" /* Safari */,
    width: 110,
    [theme.breakpoints.down("sm")]: {
      width: 100,
    },
  },

  boxItem: {
    display: "flex",
    justifyContent: "center",

    "& .box": {
      position: "relative",
      overflow: "hidden",

      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      maxWidth: 300,
      width: "100%",

      padding: "20px 10px",
      borderRadius: "8px",
      border: "0.5px solid #D2D2D2",
      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.08)",
      "&:hover": {
        border: "0.5px solid #1A427D",
      },
    },

    "& .data-row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .line": {
      width: "100%",
      height: "0.5px",
      background: "#D2D2D2",
      margin: "5% 0",
    },
    "& .logo": {
      minWidth: "35px",
      width: "35px",
      height: "35px",
      padding: 2,
      margin: "2px 10px 0 0",
      borderRadius: 8,
      border: "0.5px solid #D2D2D2",
    },
    "& .title-box": {
      display: "flex",
      alignItems: "flex-start",
    },
    "& .title": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 16,
    },
    "& .bg-box": {
      background: "#F7FBFF",
      borderRadius: 8,
      textAlign: "center",
      padding: 10,
      fontWeight: 600,
    },
    "& .title-1": {
      color: "#1A427D",
      fontSize: 16,
      textTransform: "capitalize",
    },
    "& .fee": {
      color: "#FB7800",
      fontSize: 24,
      marginTop: 4,
    },
    "& .gray": {
      color: "#646D69",
      fontSize: 14,
      marginTop: 4,
    },
    "& .discount-fee": {
      fontSize: 12,
      color: "#FF0000",
      fontStyle: "italic",
      textDecoration: "line-through",
    },
    "& .info": {
      fontSize: 14,
      color: "#646D69",
      display: "grid",
      gap: 8,
      [theme.breakpoints.down("sm")]: {
        gap: 4,
      },
    },
    "& .value": {
      color: "#343A40",
    },
    "& .value-1": {
      marginLeft: 8,
      color: "#1A427D",
      whiteSpace: "nowrap",
    },
    "& .value-2": {
      marginLeft: 10,
      color: "#1A427D",
      textAlign: "end",
    },
    "& .value-3": {
      marginLeft: 15,
      color: "#1A427D",
      textAlign: "left",

      maxWidth: "150px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .value-pre": {
      color: "#1A427D",
      whiteSpace: "pre-line",
      maxWidth: "70%",
    },
    "& .ellipsis": {
      maxWidth: "130px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .center": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .btn": {
      width: "100%",
      borderRadius: 8,
      background: "#1A427D",
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
      textTransform: "none",
      fontSize: 16,
    },
    "& .underline": {
      textDecoration: "underline",
      color: "#1A427D",
      fontStyle: "italic",
      marginTop: 10,
      cursor: "pointer",
    },
    "& .btn-icon": {
      width: 20,
      marginRight: 8,
    },
    "& .mr-discount": {
      marginRight: "21%",
    },
    "& .mr-10": {
      marginRight: "10%",
    },
    "& .discount": {
      padding: "3px",
      background: "#EC0000",
      fontSize: 14,
      color: "#fff",
      fontWeight: 500,
      textAlign: "center",

      width: "150px",
      position: "absolute",
      left: "87%",
      top: "calc(5% + 3px)",

      transformOrigin: "center",
      transform: "translate(-50%, -50%) rotate(45deg)",
    },
    "& .share": {
      position: "absolute",
      right: 6,
      top: 6,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .menu-share": {
      position: "absolute",
      background: "#fff",
      border: "0.5px solid #d2d2d2",
      borderRadius: 4,
      right: -10,
      top: 35,
      zIndex: 99,
      "&:hover .img": {
        fill: "#1A427D",
      },
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "#E3F3FF",
      color: "#1A427D",
    },

    "& .share-icon": {
      marginRight: 8,
    },
  },

  dialog: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    "& .content": {
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    "& .header": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        padding: "10px 0 5px",
      },
    },

    "& .mx-10": {
      margin: "0 10px",
    },
    "& .my-10": {
      margin: "10px 0",
    },
    "& .my-20": {
      margin: "20px 0",
    },
    "& .banner": {
      // aspectRatio: 1.72,
      width: "100%",
      display: "block",
    },
    "& .benefit-title": {
      marginRight: 4,
      whiteSpace: "nowrap",
    },
    "& .bg-div": {
      background: "#FFF6EE",
      padding: 10,
      fontStyle: "italic",
      [theme.breakpoints.down("sm")]: {
        margin: "0 8px",
      },
    },
    "& .box": {
      padding: "20px 10px",
      borderRadius: "8px",
      border: "0.5px solid #D2D2D2",
    },
    "& .logo": {
      minWidth: "35px",
      width: "35px",
      height: "35px",
      padding: 2,
      marginRight: 10,
      borderRadius: 8,
      border: "0.5px solid #D2D2D2",
    },
    "& .title-box": {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    "& .title": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 16,
    },

    "& .capitalize::first-letter": {
      textTransform: "capitalize",
    },
    "& .capitalize ul": {
      paddingInlineStart: "20px",
    },
    "& .capitalize ol": {
      paddingInlineStart: "20px",
    },

    "& .flex": {
      display: "flex",
      alignItems: "flex-start",
    },
    "& .input-label": {
      width: "60%",
      marginTop: 8,
    },

    "& .input-value": {
      width: "40%",
      color: "#1A427D",
      marginLeft: 5,
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },

      "& .MuiInputBase-input": {
        padding: 5,
      },
      "& .MuiInputBase-adornedEnd": {
        paddingRight: 5,
      },
      "& .Mui-disabled": {
        background: "#f1f1f1",
      },
    },
    "& .sub-title": {
      color: "#646D69",
      fontSize: 14,
      marginTop: 4,
    },
    "& .bg-box": {
      background: "#F7FBFF",
      borderRadius: 8,
      textAlign: "center",
      padding: 10,
      fontWeight: 600,
    },
    "& .title-1": {
      color: "#1A427D",
      fontSize: 16,
    },
    "& .fee": {
      color: "#FB7800",
      fontSize: 24,
      marginTop: 4,
    },
    "& .info": {
      color: "#646D69",
      display: "grid",
      gap: 8,
      [theme.breakpoints.down("sm")]: {
        gap: 4,
      },
    },
    "& .info-content": {
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& .row-item": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .value": {
      color: "#343A40",
    },
    "& .value-2": {
      color: "#343A40",
      textAlign: "end",
    },
    "& .title-bold": {
      color: "#1A427D",
      fontWeight: 600,
    },
    "& .document": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
    },
    "& .note": {
      color: "#646D69",
      marginTop: 4,
      fontSize: 12,
      fontStyle: "italic",
    },
    "& .btn": {
      width: "100%",
      borderRadius: 8,
      border: "1px solid #1A427D",
      background: "#1A427D",
      textTransform: "none",
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .btn-outlined": {
      width: "100%",
      borderRadius: 8,
      border: "1px solid #1A427D",
      color: "#1A427D",
      textTransform: "none",
      marginTop: 5,
      padding: 5,
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .list-item": {
      background: "#F8F9FA",
      borderRadius: 4,
      padding: 8,
      margin: "10px 0",
    },
    "& .list-item a": { color: "#0B7CE4", textDecoration: "none" },
    "& .downloadBtn": {
      cursor: "pointer",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    "& .close-icon": {
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .err": {
      fontSize: 13,
      color: "red",
      marginTop: 20,

      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .MuiOutlinedInput-root": {
      fontSize: 14,
    },
    "& .MuiInputAdornment-root .MuiTypography-root ": {
      color: "#1A427D",
      fontSize: 14,
    },
    "& .MuiDialog-paper": {
      minWidth: "80%",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
        // maxHeight: "calc(100% - 30px)",
      },
    },
    "& .MuiDialogTitle-root ": {
      padding: "15px 20px 10px",
      [theme.breakpoints.down("sm")]: {
        padding: 8,
      },
    },
    "& .MuiDialogContent-root": {
      padding: "0 10px 20px",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    "& .MuiAccordionSummary-root": {
      padding: "0 8px",
    },
    "& .MuiAccordionDetails-root": {
      padding: "10px",
      borderRadius: "0 0 8px 8px",
      border: "1px solid #D2D2D2",
      borderTop: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: "12px 0",
    },

    "& .MuiCheckbox-root": {
      padding: "0 5px 0 0",
    },

    "& .table-benefits table": {
      width: "100% !important",
      minWidth: "500px",
      margin: 0,
      border: "0px !important",
      borderSpacing: "8px",
      [theme.breakpoints.down("sm")]: {
        borderSpacing: "4px",
      },

      "& ol": {
        paddingLeft: 0,
      },
      "& td": {
        padding: "8px",
        borderRadius: "4px",
        border: "1px solid #D2D2D2 !important",
      },
      "& caption": {
        marginTop: "8px",
      },
    },
  },

  accordion: {
    marginBottom: 10,
    "& .summary": {
      minHeight: "fit-content !important",
      background: "#F3F9FF",
      color: "#1A427D",
      fontSize: 20,
      fontWeight: 600,
      border: "0.5px solid #E5EEFE",
      borderRadius: "8px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .mt-15": {
      marginTop: 15,
    },
    "& .detail": {
      display: "grid",
      gap: 10,
    },
    "& .text-bold": {
      color: "#343A40",
      fontWeight: 500,
      marginTop: 10,
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .border": {
      width: "50%",
      padding: 10,
      borderRadius: "4px",
      border: "0.5px solid #D2D2D2",
    },
    "& .label-time": {
      color: "#646D69",
      marginRight: 15,
    },
    "& .value": {
      alignContent: "center",
      textAlign: "center",
      marginLeft: 10,
      color: "#1A427D",
    },
    "& .MuiAccordion-root": {
      border: "none",
      borderRadius: 8,
    },

    "& .MuiPaper-root": { boxShadow: "none" },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      borderRadius: "8px 8px 0 0",
    },
    "& .MuiAccordion-root::before": {
      background: "#fff",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "10px 0",
    },
  },

  orderDialog: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    "& .close-icon": {
      textAlign: "right",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .titleStep": {
      color: "#1A427D",
      fontSize: 20,
      fontWeight: 600,
      textTransform: "uppercase",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
    },
    "& .mb-10": {
      marginBottom: "10px",
    },
    "& .my-10": {
      margin: "10px 0",
    },
    "& .my-15": {
      margin: "15px 0",
    },
    "& .mt-20": {
      marginTop: 20,
    },
    "& .gap-10": {
      display: "grid",
      gap: 10,
    },
    "& .textInput": {
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
      "& .Mui-disabled": {
        background: "#f1f1f1",
      },
    },
    "& .label": {
      fontWeight: 500,
      marginBottom: "5px",
      color: "#333",
    },
    "& .iconRed": {
      color: "red",
      fontWeight: 500,
    },
    "& .capitalize": {
      textTransform: "capitalize",
      "& .MuiOutlinedInput-input": {
        textTransform: "capitalize",
      },
    },
    "& .err": {
      color: "red",
      fontSize: 13,
      marginTop: "4px",
    },
    "& .btn": {
      padding: "6px 12px",
      borderRadius: 8,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .outlined": {
      color: "#1A427D",
      marginRight: 8,
    },
    "& .next": {
      color: "#fff",
      background: "#1A427D",
      border: "1px solid #1A427D",
      "&:hover": {
        color: "#fff",
        background: "#1A427D",
      },
    },
    "& .wrapper": {
      gap: 8,
      marginBottom: 8,
      [theme.breakpoints.down("sm")]: {
        gap: 4,
      },
    },
    "& .bg-color": {
      display: "flex",
      alignItems: "center",
      background: "#FFF6EE",
      padding: 8,
    },
    "& .text-color": {
      color: "#1A427D",
      fontWeight: 600,
    },
    "& .break-word": {
      wordWrap: "break-word",
    },
    "& .label-grid": {
      color: "#646D69",
      marginBottom: 5,
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& .between-row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .note-text": {
      color: "#1A427D",
      fontWeight: 600,
      textDecoration: "underline",
    },
    "& .resign": {
      color: "#FB7800",
      fontWeight: 600,
      textDecoration: "underline",
      cursor: "pointer",
    },
    "& .w-100": {
      width: "100%",
    },
    "& .border-sign": {
      border: "1px solid #DDDDDD",
      borderRadius: 10,
    },
    "& .border-sign-err": {
      border: "1px solid red",
      borderRadius: 10,
    },
    "& .lg-bold": {
      fontWeight: 600,
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& .color1": {
      color: "#1A427D",
    },
    "& .fee": {
      color: "#FB7800",
    },
    "& .err-text": {
      color: "red",
      fontSize: 12,
    },
    "& .fz-16": {
      fontSize: 16,
    },

    "& .bg-red": {
      color: "#FF0000",
      background: "#FEF2F2",
      padding: 8,
      margin: "10px 0",
    },

    "& .text-fee": {
      color: "#4B4B4B",
      fontWeight: 600,
      fontSize: 18,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .discount-fee": {
      marginRight: 20,
      color: "#FF0000",
      textDecoration: "line-through",
      fontWeight: 600,
      fontSize: 18,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .insured-title": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",

      backgroundColor: "#E5F8FF",
      fontWeight: "bold",
      margin: "10px 0 8px",
      padding: "10px 8px",
    },
    "& .insured-info": {
      color: "#343A40",
      backgroundColor: "#E5F8FF",
      fontWeight: 600,
      padding: 10,
    },
    "& .voucher": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .select-voucher": {
      display: "flex",
      alignItems: "center",
      color: "#0B7CE4",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    "& .btn-outlined": {
      borderRadius: 4,
      border: "1px solid #1A427D",
      background: "#fff",
      color: "#1A427D",
      textTransform: "none",
      boxShadow: "none",
    },

    "& .checkbox .MuiFormControlLabel-label": {
      padding: "0px !important",
    },

    "& .tab-info": {
      background: "#F8F9FA",
      borderRadius: 4,
      padding: "15px 0",
    },
    "& .tab-benefit": { lineHeight: 1.4 },
    "& .tab-bold": {
      fontWeight: 600,
      marginBottom: 4,
    },

    "& .MuiTab-root": {
      flex: 1,
      textAlign: "center",
      textTransform: "none",
      fontSize: 16,
      fontWeight: 400,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .MuiTabs-root .Mui-selected": {
      color: "#1A427D",
      fontWeight: 500,
    },
    "& .MuiTabs-indicator": {
      background: "#1A427D",
    },

    "& .bg-vat": {
      padding: "15px 10px",
      background: "#F8F9FA",
      borderRadius: 4,
    },
    "& .vat": {
      marginTop: 15,
      padding: 10,
      fontWeight: 500,
      borderRadius: 4,

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .empty": {
      color: "#FB7800",
      border: "0.5px solid #FB7800",
    },
    "& .info": {
      color: "#39B44A",
      border: "0.5px solid #39B44A",
    },
    "& .icon": {
      marginRight: 8,
      width: 20,
    },

    "& .MuiDialog-paper": {
      maxWidth: "1200px",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    "& .MuiOutlinedInput-input": {
      width: "100%",
      padding: "8px 14px",
      fontSize: 15,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiDialogActions-root": {
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 15,
        justifyContent: "center",
      },
    },
    "& .MuiFormControlLabel-root": {
      marginRight: 40,
    },
    "& .MuiDialogTitle-root ": {
      fontSize: 15,
      padding: "10px 20px",
      [theme.breakpoints.down("sm")]: {
        padding: 8,
        fontSize: 14,
      },
    },
    "& .MuiDialogContent-root": {
      padding: "0 20px 20px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 8px 8px",
      },
    },

    "& .MuiRadio-root": {
      color: "#969696",
      padding: "0 8px",
    },
    "& .MuiRadio-root.Mui-checked": {
      color: "#1A427D",
    },

    "& .error .MuiButtonBase-root": {
      color: "red",
    },

    "& .MuiButton-root.Mui-disabled": {
      border: "1px solid #D2D2D2",
      background: "#D2D2D2",
      color: "#fff",
    },

    "& .px-0 .MuiGrid2-root": {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },

  step: {
    "& .box-step": {
      textAlign: "center",
    },
    "& .label": {
      fontSize: 14,
      marginTop: 8,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
    },
    "& .await": {
      color: "#969696",
    },

    "& .MuiStepConnector-alternativeLabel": {
      background: "#fff !important",
      top: "25% !important",
      left: "calc(-50% + 25px) !important",
      right: "calc(50% + 25px) !important",
    },

    "& .MuiStepConnector-line": {
      borderColor: "#1A427D",
    },
  },

  checkbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    lineHeight: 1.3,
    "& .err": { color: "red" },
    "& .MuiCheckbox-root": {
      padding: "2px 8px 0 0",
    },
    "& .link": {
      color: "#04C",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  },

  otpDialog: {
    display: "grid",
    gap: 10,
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    "& .content": {
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .header": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        padding: "15px 10px 0",
      },
    },
    "& .my-10": {
      margin: "10px 0",
    },
    "& .my-20": {
      margin: "20px 0",
    },
    "& .mr-10": {
      marginRight: 10,
    },
    "& .container-otp": {
      display: "grid",
      gap: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 10,
      },
    },
    "& .img": { textAlign: "center" },
    "& .value": { color: "#646D69", marginTop: 4 },
    "& .border": {
      padding: 10,
      borderRadius: 8,
      background: "#F8F9FA",
      border: "0.5px solid #F8F9FA",
      "&:hover": { border: "0.5px solid #DDDDDD", cursor: "pointer" },
    },
    "& .time-out": {
      color: "#39B44A",
    },
    "& .justify": {
      justifyContent: "space-between",
    },
    "& .text-color": {
      color: "#1A427D",
      fontWeight: 500,
      "&:hover": { textDecoration: "underline", cursor: "pointer" },
    },
    "& .action-btn": {
      padding: "6px 15px",
      borderRadius: 8,
      border: "1px solid #1A427D",
      background: "#1A427D",
      color: "#fff",
      textTransform: "none",
    },
    "& .action-btn.Mui-disabled": {
      border: "1px solid #D2D2D2",
      background: "#FFF",
      color: "gray",
    },
    "& .action-btn:hover": {
      background: "#1A427D",
    },

    "& .MuiDialogActions-root": {
      padding: 0,
      [theme.breakpoints.down("sm")]: {
        padding: 10,
      },
    },
    "& .otpInput": {
      width: "30px !important",
      height: 30,
      marginRight: 15,
      borderRadius: 4,
      border: "0.5px solid #D2D2D2",
      [theme.breakpoints.down("sm")]: {
        marginRight: 8,
      },
    },
  },
}));
