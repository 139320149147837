import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import errIcon from "../../assets/icons/err-noti.svg";
import warningIcon from "../../assets/icons/warning-noti.svg";
import { formatMoney } from "../../extra/utils";

const adjustFeesName = (name) => {
  switch (name) {
    case "Increase":
      return "tăng";
    case "Decrease":
      return "giảm";
    default:
      return "Duy trì";
  }
};

function WarningOrder({ state, dispatch }) {
  const { dataStep1, dataStep2 } = state;
  const classes = useStyles();

  return (
    <Dialog
      open={!!dataStep2?.warning?.open || !!dataStep1?.warning?.open}
      keepMounted
      className={classes.warning}
    >
      <DialogContent className={classes.content}>
        {dataStep1?.warning?.type === "warningFeeFromProvider" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div>
              <div className="mt-15">
                Thông báo đơn bảo hiểm của Quý khách{" "}
                <b>{dataStep1?.buyerName}</b> bị{" "}
                <b>{adjustFeesName(dataStep1?.warning?.data?.adjustFees)}</b>{" "}
                phí bảo hiểm:
              </div>
              <div className="reason">
                Lý do: {dataStep1?.warning?.data?.adjustmentReason || ""}
              </div>
              <Divider />
              <div className="row">
                <div className="color-text2">
                  Phí bảo hiểm trước khi{" "}
                  {adjustFeesName(dataStep1?.warning?.data?.adjustFees)}:
                </div>
                <div className="color-text1">
                  {formatMoney(dataStep1?.warning?.data?.paymentFeesFromGC) ||
                    0}{" "}
                  VNĐ
                </div>
              </div>
              <div className="row">
                <div className="color-text2">
                  Phí bảo hiểm{" "}
                  {adjustFeesName(dataStep1?.warning?.data?.adjustFees)}:
                </div>
                <div className="color-text1">
                  {formatMoney(dataStep1?.warning?.data?.spreadFees) || 0} VNĐ
                </div>
              </div>
              <Divider style={{ marginTop: 10 }} />
              <div className="row fz-18">
                <div className="color-text2">
                  Tổng phí bảo hiểm sau khi{" "}
                  {adjustFeesName(dataStep1?.warning?.data?.adjustFees)}:
                </div>
                <div className="color-text1">
                  {formatMoney(
                    dataStep1?.warning?.data?.paymentFeesFromProvider
                  ) || 0}{" "}
                  VNĐ
                </div>
              </div>
            </div>
            <div className="confirm">
              Bạn có muốn tiếp tục mua bảo hiểm không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarningChangeFee")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("acceptChangeFee")}
              >
                Đồng ý
              </Button>
            </div>
          </>
        )}
        {dataStep2?.warning?.type === "warningInsuredPerson" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="mt-15">
              <div>
                Thông báo người bảo hiểm sau không được tham gia gói bảo hiểm
                này:
              </div>
              {(dataStep2?.warning?.errPerson || []).map((p, idx) => (
                <div className="list">
                  {idx + 1}. {p.insuredName}
                </div>
              ))}
            </div>
            <div className="confirm">
              Bạn có muốn bỏ ra khỏi danh sách này không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarning")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("acceptRemovalOfPerson")}
              >
                Đồng ý
              </Button>
            </div>
          </>
        )}
        {dataStep2?.warning?.type === "warningPackage" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="mt-15 center">
              <div>
                Rất tiếc, độ tuổi của{" "}
                {dataStep2?.warning?.errPerson?.[0]?.insuredName || ""} không
                được tham gia gói bảo hiểm này.
              </div>
            </div>
            <div className="confirm">
              Bạn có muốn bỏ{" "}
              {dataStep2?.warning?.errPerson?.[0]?.insuredName || ""} ra không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarning")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() =>
                  dispatch({ type: "acceptRemovalOfPerson", from: "step1" })
                }
              >
                Đồng ý
              </Button>
            </div>
          </>
        )}
        {/* {dataStep2?.warning?.type === "warningBenefit" && (
          <>
            <img src={warningIcon} alt="noti-icon" />
            <div className="mt-15">
              {`Rất tiếc, ${
                dataStep1?.buyerName || ""
              } không được tham gia quyền lợi ${
                dataStep2?.warning?.missingBenefitTitle
              }`}
            </div>
            <div className="confirm">
              Bạn có muốn bỏ quyền lợi này ra không?
            </div>
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarning")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("acceptRemovalOfBenefit")}
              >
                Đồng ý
              </Button>
            </div>
          </>
        )} */}
        {dataStep2?.warning?.type === "error" && (
          <>
            <img src={errIcon} alt="noti-icon" />
            <div className="mt-15 center">
              <div>
                Rất tiếc, người được bảo hiểm{" "}
                <b>{dataStep2?.warning?.errPerson?.[0]?.insuredName || ""}</b>{" "}
                không được tham gia gói bảo hiểm này
              </div>
            </div>
            {/* <div className="confirm">
              Bạn có muốn bỏ ra khỏi danh sách này không?
            </div> */}
            <div className="action">
              <Button
                className="btn-outlined"
                onClick={() => dispatch("closeWarning")}
              >
                Quay lại
              </Button>
              <Button
                className="btn"
                onClick={() => dispatch("closeOrderDialog")}
              >
                Trở về trang chủ
              </Button>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default WarningOrder;

const useStyles = makeStyles(() => ({
  warning: {
    "& .MuiPaper-root.MuiPaper-elevation": {
      borderRadius: 5,
      width: "90%",
      maxWidth: "500px",
      margin: 0,
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.4,
    "& .mt-15": {
      marginTop: "15px",
    },
    "& .center": {
      textAlign: "center",
    },
    "& .list": {
      marginTop: 5,
      textTransform: "capitalize",
    },
    "& .confirm": {
      textAlign: "center",
      fontWeight: "bold",
      marginTop: 15,
    },
    "& .reason": {
      color: "#FB7800",
      margin: "8px 0px",
      fontStyle: "italic",
    },
    "& .color-text1": {
      color: "#FB7800",
    },
    "& .color-text2": {
      color: "#1A427D",
    },
    "& .row": {
      marginTop: "8px",
      fontWeight: 600,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& .fz-18": {
      fontSize: 18,
      fontWeight: 600,
    },

    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 15,
    },
    "& .btn": {
      padding: "6px 15px",
      borderRadius: 6,
      textTransform: "none",
      fontSize: 14,
      fontWeight: 500,
      color: "#fff",
      background: "#0B7CE4",
      border: "1px solid #0B7CE4",
      "&:hover": {
        color: "#fff",
        background: "#0B7CE4",
      },
    },
    "& .btn-outlined": {
      padding: "6px 15px",
      marginRight: 15,
      borderRadius: 6,
      border: "1px solid #3961A0",
      background: "#fff",
      color: "#3961A0",
      textTransform: "none",
      fontSize: 14,
      boxShadow: "none",
    },
  },
}));
