import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import jsPDF from "jspdf";
import _, { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";

import {
  BpCheckedIcon,
  BpIcon,
  genders,
  renderProductName,
  searchFees,
} from "../../extra/hard-data";
import { createEventGtag, formatMoney } from "../../extra/utils";
import { useStyles } from "../../styles/bhskStyles";
import Loading from "../Loading";
import ConvertPDF from "./ConvertPDF";

import bookIcon from "../../assets/icons/book-icon.svg";
import downloadCP from "../../assets/icons/download-chao-phi.svg";
import download from "../../assets/icons/download.svg";
import error from "../../assets/icons/err.svg";
import logoDefault from "../../assets/icons/logo_default.svg";

function DetailDialog({ state, dispatch, isMobile }) {
  const {
    openDetail,
    loadDetail,
    detailData,
    detailEdit,
    errorEdit,
    providers,
  } = state;

  const classes = useStyles();
  const [expanded, setExpanded] = useState("panel1");
  const [imageLoaded, setImageLoaded] = useState(false);

  const dialogContentRef = useRef(null);
  const divFilterRef = useRef(null);
  const testRef = useRef(null);

  useEffect(() => {
    if (openDetail) {
      const timer = setTimeout(() => {
        if (divFilterRef.current) {
          divFilterRef.current.scrollIntoView({ block: "start" });
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [openDetail, imageLoaded]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);

    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTop = 0;
    }
  };

  const debouncedGetFee = useCallback(
    debounce((value) => dispatch({ type: "getFee", value }), 1000),
    []
  );

  const handleGeneratePdf = async () => {
    createEventGtag("health_home_detail_download_PDF_btn");
    dispatch({ type: "loadingCreatePDF", value: true });
    const doc = new jsPDF({
      format: "a4",
      unit: "pt",
    });

    doc.addFont(
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
      "Roboto",
      "normal"
    );
    doc.addFont(
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
      "Roboto",
      "bold"
    );
    doc.addFont(
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf",
      "Roboto",
      "italic"
    );
    doc.addFont(
      "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf",
      "Roboto",
      "bolditalic"
    );

    doc.setFont("Roboto");
    doc.setFontSize(12);

    try {
      const htmlContent = ReactDOMServer.renderToStaticMarkup(
        <ConvertPDF ref={testRef} data={detailData} detailEdit={detailEdit} />
      );

      await doc.html(htmlContent, {
        callback: (finalDoc) => {
          finalDoc.save("Chào phí BH.pdf");
        },
        x: 10,
        y: 10,
        width: 575, // A4 page width in px 595 (72 DPI) - height 842
        windowWidth: 800, // Width of the HTML content to be rendered
      });
    } catch (error) {
      console.error("Error generating PDF: ", error);
    }
    dispatch({ type: "loadingCreatePDF", value: false });
  };

  // const handleGeneratePdf2 = async () => {
  //   const pdf = new jsPDF({
  //     format: "a4",
  //     unit: "pt",
  //   });
  //   pdf.addFont(
  //     "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
  //     "Roboto",
  //     "normal"
  //   );
  //   pdf.addFont(
  //     "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
  //     "Roboto",
  //     "bold"
  //   );
  //   pdf.addFont(
  //     "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf",
  //     "Roboto",
  //     "italic"
  //   );
  //   pdf.addFont(
  //     "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bolditalic-webfont.ttf",
  //     "Roboto",
  //     "bolditalic"
  //   );

  //   pdf.setFont("Roboto");
  //   pdf.setFontSize(12);

  //   const input = ReactDOMServer.renderToStaticMarkup(
  //     <ConvertPDF ref={testRef} data={detailData} detailEdit={detailEdit} />
  //   );
  //   const marginBottom = 30;

  //   const canvas = await html2canvas(input);
  //   const imgData = canvas.toDataURL("image/png");

  //   const imgWidth = 595.28;
  //   const pageHeight = 841.89;
  //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   let heightLeft = imgHeight;
  //   let position = 0;

  //   pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //   heightLeft -= pageHeight;

  //   while (heightLeft >= 0) {
  //     position = heightLeft - imgHeight + marginBottom; // Thêm marginBottom vào vị trí
  //     pdf.addPage();
  //     pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  //     heightLeft -= pageHeight;
  //   }

  //   pdf.save("document.pdf");
  // };

  return (
    <Dialog
      open={openDetail}
      onClose={() => {
        dispatch("closeDetailDialog");
        createEventGtag("health_home_detail_close_btn");
      }}
      keepMounted
      maxWidth="lg"
      fullScreen={isMobile}
      key={detailData?.uid}
      className={classes.dialog}
    >
      {/* <ConvertPDF ref={testRef} data={detailData} detailEdit={detailEdit} /> */}
      <DialogTitle>
        <div className="header">
          <div></div>
          <div>THÔNG TIN SẢN PHẨM</div>
          <CloseIcon
            onClick={() => {
              dispatch("closeDetailDialog");
              createEventGtag("health_home_detail_close_btn");
            }}
            className="close-icon"
          />
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ position: "relative" }} ref={dialogContentRef}>
          <Loading loading={loadDetail} />
          {detailData?.images?.bannerUrl?.[0] && (
            <img
              src={detailData?.images?.bannerUrl?.[0]}
              alt="banner-image"
              className="banner my-10"
              onLoad={() => setImageLoaded(true)}
            />
          )}
          <div ref={divFilterRef} className="bg-div">
            Loại BH:{" "}
            {renderProductName(detailData?.infoFilter?.productId) || "N/A"} -
            Nhà BH:{" "}
            {providers.find((i) => i.id === detailData?.infoFilter?.providerId)
              ?.title || "N/A"}{" "}
            - Quyền lợi BH: {detailData?.categoryFilter || "N/A"} - Độ tuổi:{" "}
            {detailData?.infoFilter?.age?.type === "day"
              ? "Dưới 1 tuổi"
              : `${detailData?.infoFilter?.age?.value || ""} tuổi`}{" "}
            - Phí BH:{" "}
            {searchFees.find((i) => i.id === detailData?.infoFilter?.fees)
              ?.label || "N/A"}{" "}
          </div>
          <Grid container spacing={2} className="my-10">
            <Grid xs={12} sm={12} md={5}>
              <div className="box">
                <div className="title-box">
                  <div className="logo">
                    <img
                      src={detailData?.providerLogo || logoDefault}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div>
                    <div className="title">
                      {detailData?.productTitle || ""}
                    </div>
                    <div className="sub-title">
                      {detailData?.providerTitle || ""}
                    </div>
                  </div>
                </div>
                <Divider className="my-10" />
                <div className="bg-box">
                  <div className="title-1">
                    {detailData?.packagesTitle || ""}
                  </div>
                  <div className="fee">
                    {loadDetail ? 0 : formatMoney(detailEdit?.totalFee) || 0}{" "}
                    VNĐ
                  </div>
                </div>
                <Divider className="my-10" />
                <div className="info">
                  <div className="row-item">
                    <div>Giới tính:</div>
                    <div className="value">
                      {genders.find((i) => i.id === detailData?.gender)
                        ?.label || "N/A"}
                    </div>
                  </div>
                  <div className="row-item">
                    <div>Độ tuổi</div>
                    <div className="value">
                      {detailData?.infoFilter?.age?.type === "day"
                        ? "Dưới 1 tuổi"
                        : `${detailData?.infoFilter?.age?.value || ""} tuổi`}
                    </div>
                  </div>
                  <div className="row-item">
                    <div>Phạm vi bảo hiểm:</div>
                    <div className="value">
                      {detailData?.regions?.name || "N/A"}
                    </div>
                  </div>
                  <div className="row-item">
                    <div style={{ whiteSpace: "nowrap" }}>
                      Khu vực loại trừ:
                    </div>
                    <div className="value-2">
                      {detailData?.excludedRegion || "N/A"}
                    </div>
                  </div>
                  <Divider className="my-10" />
                  <div className="title-bold">Quyền lợi chính:</div>

                  {(detailData?.benefits?.primary || []).map(
                    (benefit, index) => (
                      <div className="row-item info-content">
                        <div className="input-label flex">
                          <div className="benefit-title">{index + 1}. </div>
                          <div className="capitalize">
                            {benefit?.benefitsTitle || ""}
                          </div>
                        </div>
                        <div className="input-value">
                          <TextField
                            fullWidth
                            size="small"
                            error={benefit?.error}
                            disabled={benefit?.categoryFees !== "phan_tram"}
                            value={
                              !detailEdit?.primary[index]?.edit_total
                                ? formatMoney(
                                    benefit?.categoryFees !== "phan_tram"
                                      ? benefit?.totalBenefits
                                      : benefit?.totalBenefitsFrom
                                  )
                                : formatMoney(
                                    detailEdit?.primary[index]?.edit_total
                                  ) || ""
                            }
                            onChange={(e) => {
                              if (
                                !"0123456789".includes(e?.nativeEvent?.data) &&
                                e?.nativeEvent?.data !== null
                              )
                                return;
                              dispatch({
                                type: "editDetail",
                                index,
                                kind: "primary",
                                value: +e.target.value.replace(/\./g, ""),
                              });
                              debouncedGetFee(
                                +e.target.value.replace(/\./g, "")
                              );
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  VNĐ
                                </InputAdornment>
                              ),
                            }}
                          />
                          {benefit?.categoryFees === "phan_tram" && (
                            <div className="note">
                              {`Từ ${
                                formatMoney(benefit?.totalBenefitsFrom) || 0
                              } đến ${
                                formatMoney(benefit?.totalBenefitsTo) || 0
                              }`}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  )}

                  {!_.isEmpty(detailData?.benefits?.additional) && (
                    <>
                      <Divider className="my-10" />
                      <div className="title-bold">Quyền lợi bổ sung:</div>
                      {(detailEdit?.additional || []).map((benefit, index) => (
                        <div className="row-item info-content">
                          <div className="input-label flex">
                            <Checkbox
                              checked={benefit?.checked}
                              onChange={(e) =>
                                dispatch({
                                  type: "checkBenefitAdditional",
                                  checked: e.target.checked,
                                  index,
                                })
                              }
                              style={{ color: "#1A427D" }}
                              icon={<BpIcon />}
                              checkedIcon={<BpCheckedIcon />}
                            />
                            <div className="benefit-title"> {index + 1}.</div>
                            <div className="capitalize">
                              {benefit?.benefitsTitle || ""}
                            </div>
                          </div>
                          <div className="input-value">
                            <TextField
                              fullWidth
                              size="small"
                              error={benefit?.error}
                              disabled={benefit?.categoryFees !== "phan_tram"}
                              value={
                                !detailEdit?.additional[index]?.edit_total
                                  ? formatMoney(
                                      benefit?.categoryFees !== "phan_tram"
                                        ? benefit?.totalBenefits
                                        : benefit?.totalBenefitsFrom
                                    )
                                  : formatMoney(
                                      detailEdit?.additional[index]?.edit_total
                                    ) || ""
                              }
                              onChange={(e) => {
                                if (
                                  !"0123456789".includes(
                                    e?.nativeEvent?.data
                                  ) &&
                                  e?.nativeEvent?.data !== null
                                )
                                  return;
                                dispatch({
                                  type: "editDetail",
                                  index,
                                  kind: "additional",
                                  value: +e.target.value.replace(/\./g, ""),
                                });
                                debouncedGetFee(
                                  +e.target.value.replace(/\./g, "")
                                );
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    VNĐ
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {benefit?.categoryFees === "phan_tram" && (
                              <div className="note">
                                {`Từ ${
                                  formatMoney(benefit?.totalBenefitsFrom) || 0
                                } đến ${
                                  formatMoney(benefit?.totalBenefitsTo) || 0
                                }`}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {errorEdit.length > 0 && (
                  <div className="err">
                    <img src={error} alt="err" />
                    <div>Giá trị mức quyền lợi không phù hợp</div>
                  </div>
                )}
                <Divider className="my-10" />

                <Button
                  variant="contained"
                  className="my-10 btn"
                  onClick={() => {
                    dispatch("openOrderDialog");
                    createEventGtag("health_home_detail_buy_now_btn");
                    dispatch({ type: "addCustomerViews", item: detailData });
                  }}
                >
                  Mua ngay
                </Button>

                <Grid container spacing={2}>
                  <Grid xs={6} sm={6} md={6}>
                    {/* <PDFDownloadLink
                      document={
                        <ExportPDF data={detailData} detailEdit={detailEdit} />
                      }
                      fileName="Chào phí BH.pdf"
                    >
                      {({ blob, url, loading: loadingPdf, error }) => (
                        <Button variant="outlined" className="btn-outlined">
                          <img
                            src={downloadCP}
                            alt="downloadCP"
                            style={{ marginRight: 2 }}
                          />
                          {"Tải chào phí"}
                        </Button>
                      )}
                    </PDFDownloadLink> */}
                    <Button
                      variant="outlined"
                      className="btn-outlined"
                      onClick={handleGeneratePdf}
                      disabled={!!detailData?.loadingPDF}
                    >
                      {detailData?.loadingPDF ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CircularProgress size={15} color="inherit" />{" "}
                          <span style={{ marginLeft: 8 }}> Đang tải...</span>
                        </div>
                      ) : (
                        <>
                          <img
                            src={downloadCP}
                            alt="downloadCP"
                            style={{ marginRight: 2 }}
                          />
                          Tải chào phí
                        </>
                      )}
                    </Button>
                  </Grid>
                  <Grid xs={6} sm={6} md={6}>
                    <Button
                      variant="outlined"
                      className="btn-outlined"
                      onClick={() => dispatch("openBooking")}
                    >
                      <img
                        src={bookIcon}
                        alt="bookIcon"
                        style={{ marginRight: 2 }}
                      />
                      Đặt hẹn tư vấn
                    </Button>
                  </Grid>
                </Grid>
              </div>
              {!isMobile && (
                <div style={{ marginTop: 20 }}>
                  <div className="my-20 document">Tài liệu sản phẩm</div>
                  {detailData?.ruleInsurance?.url?.[0] && (
                    <div className="row-item list-item">
                      <a
                        target="_blank"
                        href={detailData?.ruleInsurance?.url?.[0]}
                      >
                        Điều kiện điều khoản
                      </a>
                      {/* <img
                        src={download}
                        alt="download"
                        className="downloadBtn"
                        onClick={() =>
                          dispatch({
                            type: "downloadFile",
                            path: detailData?.ruleInsurance?.url?.[0],
                            name: `Điều kiện điều khoản`,
                          })
                        }
                      /> */}
                    </div>
                  )}

                  {/* empty document return: [null] */}
                  {detailData?.documentaion?.brochure?.[0] &&
                    (detailData?.documentaion?.brochure || []).map(
                      (path, index) => (
                        <div key={index} className="row-item list-item">
                          <a target="_blank" href={path}>
                            Brochure sản phẩm
                          </a>
                          {/* <img
                            src={download}
                            alt="download"
                            className="downloadBtn"
                            onClick={() =>
                              dispatch({
                                type: "downloadFile",
                                path,
                                name: `Brochure sản phẩm`,
                              })
                            }
                          /> */}
                        </div>
                      )
                    )}

                  {detailData?.documentaion?.excludedHospitals?.[0] &&
                    (detailData?.documentaion?.excludedHospitals || []).map(
                      (path, index) => (
                        <div key={index} className="row-item list-item">
                          <a target="_blank" href={path}>
                            Danh sách bệnh viện, phòng khám loại trừ
                          </a>
                          {/* <img
                            src={download}
                            alt="download"
                            className="downloadBtn"
                            onClick={() =>
                              dispatch({
                                type: "downloadFile",
                                path,
                                name: `Danh sách bệnh viện, phòng khám loại trừ`,
                              })
                            }
                          /> */}
                        </div>
                      )
                    )}

                  {detailData?.documentaion?.hospitalsGuarantee?.[0] &&
                    (detailData?.documentaion?.hospitalsGuarantee || []).map(
                      (path, index) => (
                        <div key={index} className="row-item list-item">
                          <a target="_blank" href={path}>
                            Danh sách bệnh viện, phòng khám bảo lãnh
                          </a>
                          {/* <img
                            src={download}
                            alt="download"
                            className="downloadBtn"
                            onClick={() =>
                              dispatch({
                                type: "downloadFile",
                                path,
                                name: `Danh sách bệnh viện, phòng khám bảo lãnh`,
                              })
                            }
                          /> */}
                        </div>
                      )
                    )}
                  {/* empty document return: [{}] */}
                  {!_.isEmpty(detailData?.documentaion?.other?.[0]) &&
                    (detailData?.documentaion?.other || []).map(
                      (path, index) => (
                        <div key={index} className="row-item list-item">
                          <a target="_blank" href={path?.url?.[0]}>
                            Các tài liệu khác
                          </a>
                          {/* <img
                            src={download}
                            alt="download"
                            className="downloadBtn"
                            onClick={() =>
                              dispatch({
                                type: "downloadFile",
                                path: path?.url?.[0],
                                name: `Các tài liệu khác`,
                              })
                            }
                          /> */}
                        </div>
                      )
                    )}
                </div>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={7} className={classes.accordion}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="summary"
                >
                  <div>Quyền lợi chính</div>
                </AccordionSummary>
                <AccordionDetails className="detail">
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: detailData?.definition || "",
                    }}
                  /> */}
                  {(detailData?.benefits?.primary || []).map(
                    (benefit, index) => (
                      <>
                        <div className="text-bold flex">
                          <div>
                            Quyền lợi chính {index + 1}:{" "}
                            <span className="capitalize">
                              {benefit?.benefitsTitle || ""}
                            </span>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="border">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: benefit?.description || "",
                              }}
                            />
                          </div>
                          <div className="border value">
                            {formatMoney(benefit?.total_benefits_from) || 0} VNĐ
                          </div>
                        </div> */}
                        <div
                          style={{
                            height: "calc(100% + 8px)",
                            overflow: "auto",
                          }}
                        >
                          <div
                            className="table-benefits"
                            dangerouslySetInnerHTML={{
                              __html: benefit?.description || "",
                            }}
                          />
                        </div>
                      </>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
              {!_.isEmpty(detailData?.benefits?.additional) && (
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className="summary mt-15"
                  >
                    <div>Quyền lợi bổ sung</div>
                  </AccordionSummary>
                  <AccordionDetails className="detail">
                    {(detailData?.benefits?.additional || []).map(
                      (benefit, index) => (
                        <>
                          <div className="text-bold flex">
                            <div>
                              Quyền lợi bổ sung {index + 1}:{" "}
                              <span className="capitalize">
                                {benefit?.benefitsTitle || ""}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              height: "calc(100% + 8px)",
                              overflow: "auto",
                            }}
                          >
                            <div
                              className="table-benefits"
                              dangerouslySetInnerHTML={{
                                __html: benefit?.description || "",
                              }}
                            />
                          </div>
                        </>
                      )
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                  className="summary mt-15"
                >
                  <div>Thời gian chờ</div>
                </AccordionSummary>
                <AccordionDetails className="detail">
                  {(detailData?.waitingTime || []).map((item, index) => (
                    <div key={index} className="row">
                      <div className="label-time">{item?.label || ""}</div>
                      <div style={{ whiteSpace: "nowrap" }}>
                        {item?.days ? `${item?.days} ngày` : "Không áp dụng"}
                      </div>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          {isMobile && (
            <div className="mx-10">
              <div className="my-10 title">Tài liệu sản phẩm</div>
              <div className="row-item list-item">
                <a target="_blank" href={detailData?.ruleInsurance?.url?.[0]}>
                  Điều kiện điều khoản
                </a>
                {/* <img
                  src={download}
                  alt="download"
                  className="downloadBtn"
                  onClick={() =>
                    dispatch({
                      type: "downloadFile",
                      path: detailData?.ruleInsurance?.url?.[0],
                      name: `Điều kiện điều khoản`,
                    })
                  }
                /> */}
              </div>
              {/*check empty document return: [null] */}
              {detailData?.documentaion?.brochure?.[0] &&
                (detailData?.documentaion?.brochure || []).map(
                  (path, index) => (
                    <div key={index} className="row-item list-item">
                      <a target="_blank" href={path}>
                        Brochure sản phẩm
                      </a>
                      {/* <img
                        src={download}
                        alt="download"
                        className="downloadBtn"
                        onClick={() =>
                          dispatch({
                            type: "downloadFile",
                            path,
                            name: `Brochure sản phẩm`,
                          })
                        }
                      /> */}
                    </div>
                  )
                )}

              {detailData?.documentaion?.excludedHospitals?.[0] &&
                (detailData?.documentaion?.excludedHospitals || []).map(
                  (path, index) => (
                    <div key={index} className="row-item list-item">
                      <a target="_blank" href={path}>
                        Danh sách bệnh viện, phòng khám loại trừ
                      </a>
                      {/* <img
                        src={download}
                        alt="download"
                        className="downloadBtn"
                        onClick={() =>
                          dispatch({
                            type: "downloadFile",
                            path,
                            name: `Danh sách bệnh viện, phòng khám loại trừ`,
                          })
                        }
                      /> */}
                    </div>
                  )
                )}
              {detailData?.documentaion?.hospitalsGuarantee?.[0] &&
                (detailData?.documentaion?.hospitalsGuarantee || []).map(
                  (path, index) => (
                    <div key={index} className="row-item list-item">
                      <a target="_blank" href={path}>
                        Danh sách bệnh viện, phòng khám bảo lãnh
                      </a>
                      {/* <img
                        src={download}
                        alt="download"
                        className="downloadBtn"
                        onClick={() =>
                          dispatch({
                            type: "downloadFile",
                            path,
                            name: `Danh sách bệnh viện, phòng khám bảo lãnh`,
                          })
                        }
                      /> */}
                    </div>
                  )
                )}

              {/* empty document return: [{}] */}
              {!_.isEmpty(detailData?.documentaion?.other?.[0]) &&
                (detailData?.documentaion?.other || []).map((path, index) => (
                  <div key={index} className="row-item list-item">
                    <a target="_blank" href={path?.url?.[0]}>
                      Các tài liệu khác
                    </a>
                    {/* <img
                      src={download}
                      alt="download"
                      className="downloadBtn"
                      onClick={() =>
                        dispatch({
                          type: "downloadFile",
                          path: path?.url?.[0],
                          name: `Các tài liệu khác`,
                        })
                      }
                    /> */}
                  </div>
                ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DetailDialog;
