import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Dialog,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import address from "../../assets/icons/vat-address.svg";
import mst from "../../assets/icons/vat-mst.svg";
import name from "../../assets/icons/vat-user.svg";

function Vat({ data, dispatch }) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!data?.openVat}
      className={classes.info}
    >
      <div className="content">
        <div className="row header">
          <div>
            Lựa chọn hoá đơn <span className="iconRed">*</span>
          </div>
          <CloseIcon onClick={() => dispatch("closeVat")} />
        </div>

        <div>
          <RadioGroup
            row
            value={data?.vat?.type}
            onChange={(e) =>
              dispatch({
                type: "onChangeVat",
                name: "type",
                value: e.target.value,
              })
            }
            className="checkbox"
          >
            <FormControlLabel
              value="PERSONAL"
              control={<Radio />}
              label="Cá nhân"
            />
            <FormControlLabel
              value="BUSINESS"
              control={<Radio />}
              label="Doanh nghiệp"
              style={{ marginLeft: 10 }}
            />
          </RadioGroup>
        </div>
        <div>
          <div className="label">
            Họ và tên <span className="iconRed">*</span>
          </div>
          <TextField
            fullWidth
            size="small"
            value={data?.vat?.name || ""}
            placeholder="Nhập thông tin"
            className="capitalize"
            onChange={(e) =>
              dispatch({
                type: "onChangeVat",
                name: "name",
                value: e.target.value,
              })
            }
            InputProps={{
              startAdornment: <img src={name} alt="name" className="icon" />,
            }}
          />
        </div>
        <div>
          <div className="label">
            Mã số thuế <span className="iconRed">*</span>
          </div>
          <TextField
            fullWidth
            size="small"
            value={data?.vat?.taxCode || ""}
            placeholder="Nhập thông tin"
            onChange={(e) => {
              dispatch({
                type: "onChangeVat",
                name: "taxCode",
                value: e.target.value,
              });
            }}
            InputProps={{
              startAdornment: <img src={mst} alt="mst" className="icon" />,
            }}
          />
        </div>

        <div>
          <div className="label">
            Địa chỉ <span className="iconRed">*</span>
          </div>
          <TextField
            fullWidth
            size="small"
            value={data?.vat?.address || ""}
            placeholder="Nhập địa chỉ"
            onChange={(e) => {
              dispatch({
                type: "onChangeVat",
                name: "address",
                value: e.target.value,
              });
            }}
            InputProps={{
              startAdornment: (
                <img src={address} alt="address" className="icon" />
              ),
            }}
          />
        </div>

        <div className="action">
          <Button
            className="btn"
            disabled={
              !data?.vat?.name || !data?.vat?.taxCode || !data?.vat?.address
            }
            onClick={() => dispatch("updateVat")}
          >
            Cập nhật
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default Vat;

const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },

    "& .MuiDialog-paper": {
      maxWidth: "450px",
      borderRadius: 16,
      margin: 10,
      [theme.breakpoints.down("sm")]: {
        minWidth: "95vw",
      },
    },
    "& .content": {
      display: "grid",
      gap: 15,
      padding: 25,
      [theme.breakpoints.down("sm")]: {
        padding: "20px 15px",
      },
    },
    "& .row": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .header": {
      color: "#1A427D",
      fontWeight: 600,
      fontSize: 20,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },

    "& .label": {
      fontWeight: 500,
      marginBottom: "6px",
      color: "#333",
    },
    "& .iconRed": {
      color: "red",
      fontWeight: 500,
    },
    "& .capitalize": {
      textTransform: "capitalize",
      "& .MuiOutlinedInput-input": {
        textTransform: "capitalize",
      },
    },
    "& .icon": { marginRight: 8 },

    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 10,
    },
    "& .btn": {
      width: "50%",
      padding: "6px 12px",
      borderRadius: 4,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 500,
      color: "#fff",
      background: "#1A427D",
      border: "1px solid #1A427D",
      "&:hover": {
        color: "#fff",
        background: "#1A427D",
      },
    },
    "& .Mui-disabled": {
      background: "#f1f1f1",
      border: "1px solid #f1f1f1",
    },
    "& .MuiOutlinedInput-input": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },

    "& .MuiRadio-root": {
      color: "#969696",
      padding: "0 8px",
    },
    "& .MuiRadio-root.Mui-checked": {
      color: "#1A427D",
    },
  },
}));
