import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Banner from "../components/Banner";
import Products from "../components/Products";
import { useStyles } from "../styles/homeStyles";
import BHSucKhoe from "./BHSucKhoe";

import comingSoon from "../assets/icons/coming-soon.svg";

function Home({ isMobile, dispatch, state }) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        const benefitElement = document.getElementById("benefit");
        if (benefitElement) {
          const elementPosition = benefitElement.getBoundingClientRect().top;
          if (elementPosition < 350) {
            dispatch({ type: "onChangeScroll", value: true });
          } else {
            dispatch({ type: "onChangeScroll", value: false });
          }
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const renderProductPage = () => {
    const params = new URLSearchParams(location.search);
    const currentProduct = params.get("code");

    switch (currentProduct) {
      case "health":
        return (
          <BHSucKhoe
            isMobile={isMobile}
            productSelected={state.productSelected}
          />
        );
      default:
        return (
          <div style={{ textAlign: "center", margin: "15% 0" }}>
            <img src={comingSoon} alt="coming-soon" className="coming-logo" />
          </div>
        );
    }
  };

  return (
    <div className={classes.container}>
      <Banner isMobile={isMobile} banners={state.banners} />
      <Products isMobile={isMobile} state={state} dispatch={dispatch} />
      {renderProductPage()}
    </div>
  );
}

export default Home;
